.balance-row {
    margin-top: 32px;
    margin-bottom: 32px;
    height: 16px;
    width: 300px;
    background-color: #743f39;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    position: relative;
    font-size: 0;

    .balance-time {
        flex: 0 0 16px;
        height: 16px;
        transform: scale(2);
        image-rendering: pixelated;
        font-size: 0;
        position: relative;
        z-index: 9;

        .letter {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    .balance-container {
        flex: 1 1 50%;
    }

    .balance-val {
        height: 16px;
        background-color: #ccc;
        transition: width .1s ease;
        mix-blend-mode: overlay;
    }

    .balance-low {
        float: right;
    }

    .low-balance-icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translate(-75%, -50%) scale(2);
        margin-top: -4px;
        width: 24px;
        height: 24px;
        image-rendering: pixelated;
    }

    .high-balance-icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translate(75%, -50%) scale(2);
        margin-top: -2px;
        width: 24px;
        height: 24px;
        image-rendering: pixelated;
    }

    .balance-count {
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translate(-8px, -50%);
        font-size: 0;
        text-align: right;

        &.count-trips {
            right: auto;
            left: 100%;
            text-align: left;
            transform: translate(8px, -50%);
        }
    }

    @media screen and (max-width: 640px) {
        width: 200px;
        margin-top: 24px;
        margin-bottom: 24px;

        .balance-time {
            transform: scale(2) translateY(0);
            margin-left: -4px;
            margin-right: -4px;
        }

        .balance-count {
            top: 200%;
            right: auto;
            left: 0;

            &.count-trips {
                left: auto;
                right: 0;
            }
        }
    }
}