$row-slug: l-row !default;
$col-slug: l-col !default;
        
@function offset( $value ) {
    @return -16px * $value;
}

.bitmap-font {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;

    .word {
        display: inline-block;
        white-space: nowrap;
        padding-right: 6px;

        .letter:first-child:last-child {
            margin: 0 -6px 0 0;
        }
    }

    &.font-dark {
        .letter {
            background-image: url(/fonts/dark.png);
            width: 14px;
        }
    }

    &.font-red {
        .letter {
            background-image: url(/fonts/red.png);
        }
    }
    
    .letter {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        background: url(/fonts/big.png) no-repeat 0 0;
        margin-right: -5px;

        @for $i from 0 through 16 {
            &.#{$row-slug}-#{$i} {
                background-position-y: offset( $i );
            }
            &.#{$col-slug}-#{$i} {
                background-position-x: offset( $i );
            }
        }

        &.l-code-0 {
            width: 8px;
        }

        &.l-code-12 {
            margin-left: -2px;
            margin-right: -8px;
        }

        &.l-code-45 {
            margin-left: 2px;
            margin-right: -4px;
        }

        &.l-code-65 {
            margin-right: -5px;
        }

        &.l-code-71 {
            margin-right: -5px;
        }

        &.l-code-73 {
            margin-left: -1px;
            margin-right: -6px;
        }

        &.l-code-76 {
            margin-left: -4px;
            margin-right: -7px;
        }

        &.l-code-77 {
            margin-left: 2px;
            margin-right: -3px;
        }

        &.l-code-82 {
            margin-left: 1px;
        }

        &.l-code-84 {
            margin-left: -1px;
        }

        &.l-code-87 {
            margin-left: 2px;
        }

        &.l-code-89 {
            margin-left: 1px;
        }

        &.l-code-41 + .l-code-68 {
            margin-left: -1px;
        }

        &.l-code-48 + .l-code-76 {
            margin-left: -2px;
        }

        &.l-code-65 + .l-code-76 {
            margin-left: -1px;
        }

        &.l-code-65 + .l-code-84 {
            margin-left: 0;
        }
    }
}