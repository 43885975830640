$windows-count: 5;
$curtains-count: 44;
$decor-count: 46;

.room {
    display: block;
    width: 224px;
    height: 160px;
    position: relative;
    padding: 8px;
    margin: -.5px;

    .light-layer {
        margin: 0 2px;
        height: 100%;
        z-index: 0;
    }

    &.light-off .light-layer {
        background-color: #3a4466 !important;
    }

    &:hover {
        .room-number {
            transform: translateX(-50%) scale(1) rotate(45deg) ;
        }
    }

    .room-balance {
        position: absolute;
        right: 8px;
        top: 0;
        transform: scale(2);
        image-rendering: pixelated;
        z-index: 10;
    }

    .room-number {
        position: absolute;
        left: 50%;
        top: 8px;
        transform: translateX(-50%) scale(0) rotate(45deg) ;
        z-index: 9;
        background-color: #3f2832;
        width: 24px;
        height: 24px;
        transition: transform .2s ease;
        overflow: hidden;

        .center-container {
            overflow: visible;
            transform: rotate(-45deg);
        }
    }

    .room-aura {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        pointer-events: none;
        mix-blend-mode: hard-light;

        &.good {
            background: url(/images/good-aura.png) no-repeat center center;
            background-size: cover;
        }

        &.bad {
            background: url(/images/bad-aura.png) no-repeat center center;
            background-size: cover;
        }
    }

    .persons-layer {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: .75em;
    }

    .wall-layer {
        background: url(/images/walls/5.png) no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 8;
        image-rendering: pixelated;
        pointer-events: none;
    }

    &.room-first .wall-layer {
        background-image: url(/images/walls/4.png);
    }

    &.room-last .wall-layer {
        background-image: url(/images/walls/6.png);
    }

    .window-layer {
        background: none no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        z-index: 10;
        image-rendering: pixelated;
        pointer-events: none;
        
        @for $i from 1 through $windows-count {
            &.type-#{$i} {
                background-image: url(/images/windows/#{$i}.png);
            }
        }
    }

    .curtains-layer {
        background: none no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        z-index: 6;
        image-rendering: pixelated;
        pointer-events: none;
        opacity: .5;

        &.type-0 {
            background-image: none;
        }
        
        @for $i from 1 through $curtains-count {
            &.type-#{$i} {
                background-image: url(/images/curtains/#{$i}.png);
            }
        }
    }

    .decor-layer {
        background: none no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        z-index: 7;
        image-rendering: pixelated;
        pointer-events: none;

        &.type-0 {
            background-image: none;
        }
        
        @for $i from 1 through $decor-count {
            &.type-#{$i} {
                background-image: url(/images/decor/#{$i}.png);
            }
        }
    }

    .desaturate {
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        background-color: #666;
        mix-blend-mode: saturation;
    }
}

.floor-first {
    .wall-layer {
        background-image: url(/images/walls/2.png);
    }

    .room.room-first .wall-layer {
        background-image: url(/images/walls/1.png);
    }

    .room.room-last .wall-layer {
        background-image: url(/images/walls/3.png);
    }
}

.floor-last {
    .wall-layer {
        background-image: url(/images/walls/8.png);
    }

    .room.room-first .wall-layer {
        background-image: url(/images/walls/7.png);
    }

    .room.room-last .wall-layer {
        background-image: url(/images/walls/9.png);
    }
}