.splash {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 999;
    opacity: 1;
    transition: opacity 1s 1s ease;

    img {
        max-width: 90%;
        transition: opacity 1s ease;
        animation: fade-in 1s 1 ease;
    }

    &.hidden {
        img {
            opacity: 0;
        }

        opacity: 0;
        pointer-events: none;
    }
}