* {
    user-select: none;
}

.App {
    height: 100%;
    overflow-y: auto;
    font-family: 'Segoe UI', sans-serif;

    h1 {
        transform: scale(2);
        image-rendering: pixelated;
        margin-bottom: 64px;
    }
}

.app-page {
    height: 100%;

    .ant-layout {
        background-color: #142634;
        color: #fff;
    }

    &.app-page-game {
        overflow-y: auto;
    }
}

.ant-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-bottom: 8px;

    .ant-col {
        text-align: left;
        
        &:first-child {
            padding-right: 24px;
            text-align: right;
        }
    }
}

.app-version {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    padding: 8px;
    font-size: .5em;
    opacity: .5;
}