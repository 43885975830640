.game-button {
    cursor: pointer;
    position: relative;
    min-width: 32px;
    background-color: #e4a672;
    box-shadow: 
        0px 2px inset #ead4aa, 
        0px -4px inset #e8b796, 
        0px 2px #743f39,
        0px -2px #743f39,
        2px 0px #743f39,
        -2px 0px #743f39;

    .button-icon {
        width: 32px;
        height: 32px;
        background: none no-repeat top center;
        background-size: 100% 200%;
        image-rendering: pixelated;
        margin: -2px 0;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 100%;
        background: linear-gradient(
            180deg, 
            rgba(116,63,57,1) 0%, 
            rgba(116,63,57,1) 7%, 
            #ead4aa 7%, 
            #ead4aa 14%, 
            rgba(0,0,0,0) 14%, 
            rgba(0,0,0,0) 79%, 
            #e8b796 79%, 
            #e8b796 93%, 
            rgba(116,63,57,1) 93%, 
            rgba(116,63,57,1) 100%
        );
    }

    &:after {
        left: auto;
        right: 0;
    }

    &:focus {
        outline: none;
    }

    &.active,
    &:active {
        background-color: #b86f50;
        box-shadow: 
            0px 2px inset #743f39, 
            0px -2px inset #e4a672, 
            0px 2px #743f39,
            0px -2px #743f39,
            2px 0px #743f39,
            -2px 0px #743f39;

        .button-icon {
            background-position: bottom center;
        }

        & > * {
            transform: translateY(1px);
        }    
        
        &:before,
        &:after {
            background: linear-gradient(
                180deg, 
                rgba(116,63,57,1) 0%, 
                rgba(116,63,57,1) 7%, 
                rgba(0,0,0,0) 7%, 
                rgba(0,0,0,0) 86%, 
                #e4a672 86%, 
                #e4a672 93%, 
                rgba(116,63,57,1) 93%, 
                rgba(116,63,57,1) 100%
            );
        }
    }

    * {
        cursor: pointer;
    }

    & > * {
        font-size: 0;
        display: block;
        padding: 8px;
        margin-left: -2px;
        margin-right: -2px;
        margin-top: -2px;
        margin-bottom: -2px;
    }

    &.big > * {
        padding: 16px;
    }

    @media screen and (max-width: 640px) {
        box-shadow: 
            0px 1px inset #ead4aa, 
            0px -2px inset #e8b796, 
            0px 1px #743f39,
            0px -1px #743f39,
            1px 0px #743f39,
            -1px 0px #743f39;
    
        &:before,
        &:after {
            background: linear-gradient(
                180deg, 
                rgba(116,63,57,1) 0%, 
                rgba(116,63,57,1) 3.5%, 
                #ead4aa 3.5%, 
                #ead4aa 7%, 
                rgba(0,0,0,0) 7%, 
                rgba(0,0,0,0) 89.5%, 
                #e8b796 89.5%, 
                #e8b796 96.5%, 
                rgba(116,63,57,1) 96.5%, 
                rgba(116,63,57,1) 100%
            );
        }

        &.active,
        &:active {
            box-shadow: 
                0px 1px inset #743f39, 
                0px -1px inset #e4a672, 
                0px 1px #743f39,
                0px -1px #743f39,
                1px 0px #743f39,
                -1px 0px #743f39;
    
            & > * {
                transform: translateY(1px);
            }

            &:before,
            &:after {
                background: linear-gradient(
                    180deg, 
                    rgba(116,63,57,1) 0%, 
                    rgba(116,63,57,1) 3.5%, 
                    rgba(0,0,0,0) 3.5%, 
                    rgba(0,0,0,0) 93%, 
                    #e4a672 93%, 
                    #e4a672 96.5%, 
                    rgba(116,63,57,1) 96.5%, 
                    rgba(116,63,57,1) 100%
                );
            }
        }

        & > * {
            margin-left: -1px;
            margin-right: -1px;
            margin-top: -1px;
            margin-bottom: -1px;
        }

        .button-icon {
            margin: -1px 0;
        }
    }
}