.slider-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;

    label {
        font-size: 0;
        margin-right: 16px;
    }

    input {
        opacity: 0;
        width: 200px;
        transition: width .2s ease;
    }

    &.vertical {
        label {
            margin-right: 0;
        }
        input {
            width: 100px;
        }
    }

    .slider-input-container {
        position: relative;
        display: inline-block;
        font-size: 0;

        &.vertical {
            position: absolute;
            left: 50%;
            top: 100%;
            transform: rotate( 90deg );
            transform-origin: 0 50%;
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
            transition: opacity .2s ease;

            input {
                width: 0;
            }
        }

        &.visible {
            opacity: 1;
            pointer-events: all;
            transition: opacity .1s ease;
        }
    }

    .slider-fake {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .slider-fake-bar {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 4px;
            background-color: #743f39;
        }

        .slider-fake-thumb {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-50%,-50%);
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background-color: #b86f50;
            border: 2px solid #e4a672;
        }

        .slider-fake-tick {
            position: absolute;
            top: 100%;
            transform: rotate(-90deg);
            width: 8px;
            height: 16px;
            font-size: 0;
        }
        
        .slider-fake-low {
            left: 0;
        }

        .slider-fake-high {
            right: 0;
        }
    }
}